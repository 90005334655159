<template>
    <div
        v-if="showBanner"
        id="cookie-banner"
        class="antialiased fixed md:absolute bottom-0 inset-x-0 md:left-auto md:right-0 md:mb-10 md:mr-10 bg-white shadow-[0px_-4px_12px_0px_rgba(0,0,0,0.24)] md:shadow-[0px_4px_12px_0px_rgba(0,0,0,0.24)] py-10 px-6 flex flex-col gap-6 items-center justify-between md:max-w-sm z-30 rounded-t-3xl md:rounded-3xl">
        <h3 class="font-semibold text-2xl">Accept cookies?</h3>
        <p class="text-xs md:text-sm text-[#8C8C8C] font-medium">
            We use performance cookies to provide a better app experience. Rejecting these cookies will make some features and support unavailable to you.
            <a class="font-semibold underline text-[#555BA2]" href="https://www.valence.co/other/privacy#cookies">Learn more here</a>.
        </p>
        <div class="flex flex-col items-center justify-between gap-4">
            <button class="max-h-[3rem] py-3 px-5 leading-none rounded-[20px] h-auto w-full font-semibold bg-[#555BA2] text-white" @click="acceptAllCookies">
                Accept All Cookies
            </button>
            <button class="max-h-[3rem] py-3 px-5 leading-none rounded-[20px] h-auto w-full font-semibold text-[#555BA2]" @click="rejectCookies">Reject Performance Cookies</button>
        </div>
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { onMounted, ref } from "vue";

const showBanner = ref(false);

onMounted(async () => {
    const cookieConsent = localStorage.getItem("cookie_consent");
    if (cookieConsent === "persisted") {
        return;
    }

    const response = await fetch("/accounts/cookie-preferences/" + (cookieConsent || ""), {
        method: "GET",
        headers: { "X-CSRFToken": getCookie("csrftoken") },
    });
    if (response.status != 200) {
        console.error("Failed to get cookie preferences");
        return;
    }
    const data = await response.json();
    if (!data.cookie_preferences) {
        showBanner.value = true;
        return;
    }

    if (data.cookie_preferences === "updated" || !cookieConsent) {
        localStorage.setItem("cookie_consent", "persisted");
    }
});

const updatePreferences = async (choice) => {
    const response = await fetch("/accounts/cookie-preferences/", {
        method: "PUT",
        body: JSON.stringify({ choice, method: "banner" }),
        headers: { "X-CSRFToken": getCookie("csrftoken"), "Content-Type": "application/json" },
    });
    if (response.status != 204) {
        console.error("Failed to update cookie preferences");
        return null;
    }
    localStorage.setItem("cookie_consent", `?consent_method=banner&consent_choice=${choice}&consent_at=${new Date().toISOString()}`);
    return response;
};

async function rejectCookies() {
    const response = await updatePreferences("performance");
    if (response) {
        showBanner.value = false;
    }
}

async function acceptAllCookies() {
    const response = await updatePreferences("all");
    if (response) {
        showBanner.value = false;
    }
}
</script>
